//Create pallete on https://material.io/resources/color/
/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMemo, useState } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { ThemeModeContext } from "../contexts/ThemeModeContext";

interface ColorOverrides {
  note: true;
}

declare module "@mui/material/styles" {
  interface Palette {
    note: Palette["primary"];
  }
  interface PaletteOptions {
    note: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides extends ColorOverrides {}
}

const theme = (mode: any) => {
  return responsiveFontSizes(
    createTheme({
      typography: {
        fontFamily: ["Galeana", "sans-serif", "Arial"].join(","),
        fontWeightLight: "normal",
        fontWeightRegular: "normal",
        fontWeightMedium: "bold",
        fontWeightBold: "bold",
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: "1em",
              borderRadius: 5,
              padding: ".8em 2em",
              verticalAlign: "middle",
            },
            sizeMedium: {
              fontSize: ".9em",
              padding: ".4em 2em",
            },
            sizeSmall: {
              fontSize: ".8em",
              padding: ".2em 1.2em",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: "5px !important",
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            textColorInherit: {
              color: "#FFF",
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: "10px !important",
              padding: 20,
              backgroundColor: "#F9FCFE !important",
              border: "2px solid #FFF",
              boxShadow: "none",
            },
          },
        },
      },
      palette: {
        mode,
        primary: {
          main: "#09043e",
          light: "#0f066c",
          dark: "#050221",
          contrastText: "#fff",
        },
        secondary: {
          main: "#be003e",
          light: "#ec004d",
          dark: "#8c002e",
          contrastText: "#fff",
        },
        success: {
          main: "#6eaa2e",
          light: "#88D339",
          dark: "#4B741F",
          contrastText: "#fff",
        },
        warning: {
          main: "#feef35",
          light: "#F6F485",
          dark: "#afa424",
          contrastText: "#000",
        },
        info: {
          main: "#0dcfff",
          light: "#61dfff",
          dark: "#0A85A4",
          contrastText: "#000",
        },
        error: {
          main: "#fd6643",
          light: "#FF957C",
          dark: "#D55234",
          contrastText: "#fff",
        },
        note: {
          main: "#FFF",
        },
        background: {
          default: mode === "light" ? "#F9FCFE" : "#181d3a",
          paper: mode === "light" ? "#fff" : "#0b0d26",
        },
      },
    }),
  );
};

const ThemesProvider = (props: any) => {
  const localMode =
    localStorage.getItem(`${process.env.REACT_APP_KEYNAME}themeMode`) ||
    "light";
  const [mode, setMode] = useState(localMode);

  const change = async () => {
    const newMode = mode === "light" ? "dark" : "light";

    setMode(newMode);

    localStorage.setItem(`${process.env.REACT_APP_KEYNAME}themeMode`, newMode);
  };

  const value = useMemo(
    () => ({
      mode,
      change,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode],
  );

  return (
    <ThemeModeContext.Provider value={value}>
      <ThemeProvider theme={theme(mode)}>{props.children}</ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export default ThemesProvider;
