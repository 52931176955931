import { create } from "zustand";
import { initialQuestions } from "../utils/questions";

type State = {
  questions: Array<object>;
  current: any;
  answered: Array<object>;
};

type Actions = {
  generateQuestion: () => void;
  reset: () => void;
  end: () => void;
};

const initialState: State = {
  questions: initialQuestions,
  current: {},
  answered: [],
};

const useQuiz = create<State & Actions>((set, get) => ({
  ...initialState,

  generateQuestion: () => {
    const newQuestions: any = get().questions;
    const rand = Math.floor(Math.random() * newQuestions.length);

    set(() => ({ current: newQuestions[rand] }));

    set(() => ({
      questions: newQuestions.filter(
        (row: any) => row.question != newQuestions[rand].question,
      ),
    }));
  },

  reset: () =>
    set(() => ({
      ...initialState,
    })),

  end: () =>
    set(() => ({
      questions: [],
    })),
}));

export default useQuiz;
