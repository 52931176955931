import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Logos from "../../assets/images/logos.webp";

import useQuiz from "../../stores/quiz";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const TypographyStyled = (props: any) => {
  return (
    <Typography {...props} fontStyle="italic" variant="h2" textAlign="center">
      {props.children}
    </Typography>
  );
};

const End = (props: any) => {
  const [rand, setRand] = useState(0);
  const end = useQuiz((state) => state.end);

  useEffect(() => {
    setRand(Math.floor(Math.random() * 3));
  }, [end]);

  useEffect(() => {
    console.log(rand);
  }, [rand]);

  return (
    <>
      <Stack
        direction="column"
        spacing={4}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        height="55vh"
      >
        <Stack direction="column" alignItems="center">
          <img src={Logos} width={300} />
          <Typography
            variant="h4"
            color="white"
            textAlign="center"
            fontStyle="italic"
            sx={{ mt: 5 }}
          >
            Obrigado pela participação!
          </Typography>
        </Stack>
        <Stack direction="column" alignItems="center">
          {rand === 0 && (
            <>
              <TypographyStyled fontWeight="bold" color="secondary.main">
                Jogos Olímpicos Paris 2024
              </TypographyStyled>
              <TypographyStyled fontWeight="normal" color="white">
                é no <b>sportv</b>
              </TypographyStyled>
            </>
          )}
          {rand === 1 && (
            <>
              <TypographyStyled fontWeight="normal" color="white">
                Cobertura completa dos
              </TypographyStyled>
              <TypographyStyled fontWeight="bold" color="secondary.main">
                Jogos Olímpicos Paris 2024
              </TypographyStyled>
              <TypographyStyled fontWeight="normal" color="white">
                é no <b>sportv</b>
              </TypographyStyled>
            </>
          )}
          {rand === 2 && (
            <>
              <TypographyStyled fontWeight="normal" color="white">
                Você acompanha os
              </TypographyStyled>
              <TypographyStyled fontWeight="normal" color="white">
                próximos capítulos do
              </TypographyStyled>
              <TypographyStyled fontWeight="bold" color="secondary.main">
                Time Brasil no <b>sportv</b>
              </TypographyStyled>
            </>
          )}
          <Button
            startIcon={<RestartAltIcon fontSize="large" />}
            color="note"
            variant="outlined"
            size="large"
            onClick={() => {
              props.restart();
            }}
            sx={{
              fontSize: 30,
              fontStyle: "italic",
              p: "0 10px 0 10px",
              mt: 3,
            }}
          >
            Reiniciar
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default End;
