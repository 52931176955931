import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Ilustra from "../../assets/images/ilustrawebp.webp";
import Bg from "../../assets/images/bg-ilustra.webp";
import LogoSportv from "../../assets/images/logo-sportv-light.webp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const Index = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState<null | number>(null);
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    if (count === 0) {
      navigate("/start");
    } else if (count !== null) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [count, navigate]);

  const initCount = () => {
    setBackdrop(true);
    setCount(3);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        background: `#09043e`,
        backgroundImage: `url(${Bg})`,
        backgroundSize: "cover",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <Typography fontSize={350} fontStyle="italic" fontWeight="bold">
          {count}
        </Typography>
      </Backdrop>
      <Container
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{
            position: "absolute",
            left: 30,
            top: 30,
          }}
        >
          <img src={LogoSportv} width={200} />
          <Typography
            variant="h4"
            fontWeight="normal"
            fontStyle="italic"
            textAlign="center"
            color="white"
            sx={{ mt: 3, mb: "-10px" }}
          >
            Cobertura completa dos
          </Typography>
          <Typography
            variant="h4"
            fontWeight="bold"
            fontStyle="italic"
            textAlign="center"
            color="secondary.main"
          >
            Jogos Olímpicos Paris 2024
          </Typography>
        </Box>
        <Link
          component="button"
          onClick={() => {
            initCount();
          }}
        >
          <img src={Ilustra} style={{ maxHeight: "80vh", maxWidth: "70vw" }} />
        </Link>
        <Button
          startIcon={<PlayCircleOutlineIcon fontSize="large" />}
          color="note"
          variant="outlined"
          size="large"
          onClick={() => {
            initCount();
          }}
          sx={{
            fontSize: 30,
            fontStyle: "italic",
            p: "0 10px 0 10px",
          }}
        >
          Começar
        </Button>
      </Container>
    </Container>
  );
};

export default Index;
