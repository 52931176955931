import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../layouts";
import Index from "../views/Index";
import Quiz from "../views/Index/quiz";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/start" element={<Layout />}>
          <Route index element={<Quiz />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
