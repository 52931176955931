import { Outlet } from "react-router-dom";
import { Container, Box, Alert } from "@mui/material";

import Bg from "../assets/images/bg-quiz.webp";
import Logos from "../assets/images/logos.webp";
import shapeTopRight from "../assets/images/shape-top-right.webp";
import shapeBottomLeft from "../assets/images/shape-bottom-left.webp";

import useQuiz from "../stores/quiz";

function Layout() {
  const questions = useQuiz((state) => state.questions);

  return (
    <Container
      maxWidth={false}
      sx={{
        background: `#09043e`,
        backgroundImage: `url(${Bg})`,
        backgroundSize: "cover",
      }}
    >
      <Container
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Outlet />
      </Container>
      <img
        src={shapeTopRight}
        style={{
          width: 400,
          position: "absolute",
          top: 0,
          right: 0,
        }}
      />
      <img
        src={shapeBottomLeft}
        style={{
          width: 400,
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 2,
        }}
      />
      {questions.length > 0 && (
        <Box
          width="100%"
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            backgroundColor: "primary.main",
            py: 3,
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <img src={Logos} width={150} />
        </Box>
      )}
    </Container>
  );
}

export default Layout;
